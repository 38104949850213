import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./Login";
import Reset from "./Reset";
import Dashboard from "./Dashboard";
import Calendar from "./Calendar";
import Records from "./Records";
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image';
import PrivateRoutes from './PrivateRoutes';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

function App() {
  return (
    <div className="app">
      <header>
        <Container>
          <Row style={{padding: '15px 0'}}>
          <Col xs={6} md={4} className="d-none d-md-flex align-items-center"><b>Advanced Orthopedics</b></Col>
          <Col xs={6} sm={6} md={4} className="d-flex align-items-center justify-content-center"><b>On Call Schedule</b></Col>
          <Col xs={6} sm={6} md={4}><Image src="/boadocsLogoWhite.png" fluid style={{maxHeight: '55px', float: 'right'}}/></Col>
          </Row>
        </Container>
      </header>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/reset" component={Reset} />
          <PrivateRoutes exact path="/dashboard" component={Dashboard} />
          <PrivateRoutes exact path="/calendar" component={Calendar} />
          <PrivateRoutes exact path="/records" component={Records} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
