import AdminMenu from "./AdminMenu"
import Container from 'react-bootstrap/Container';
import Calendar from "./Calendar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


export default function PrivateRoutes({...rest}) {
    return (
        <>
        <Container>
            <AdminMenu />
        </Container>
        <Route {...rest}/>
        </>
    )
}
