import React, { useEffect, useState, useRef, Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import { auth, db, logout } from "./firebase";
import EditModal from './EditModal'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser from 'react-html-parser';
import ReactToPrint from 'react-to-print';
import PrintRounds from './PrintRounds';
import ReactTooltip from 'react-tooltip'
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import dateFormat from "dateformat";
import RecordDeleteModal from "./RecordDeleteModal";
  
export default function Records() {
  const RecordTheme = React.lazy(  async() => import('./RecordTheme'));
  const [deleteShow, setDeleteShow] = useState(false)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showing, setShowing] = useState(false);
  const [docId, setDocId] = useState(false)
  const [deleteDocId, setDeleteDocId] = useState(false);
  
  //Form Inputs
  const [name, setName] = useState(false);
  const [age, setAge] = useState(false);
  const [sex, setSex] = useState(false);
  const [note, setNote] = useState(false);
  const [location, setLocation] = useState(false);
  // const [docId, setDocId] = useState(false);
  const [status, setStatus] = useState(false);
  const [area, setArea] = useState(false);
  const [physician, setPhysician] = useState(false);

  const [records, setRecords] = useState([]);

  const [user, loading, error] = useAuthState(auth);
  const [lastEdit, setLastEdit] = useState("");
  const history = useHistory();

  const editShow = async (e) => {
    await setDocId(e.target.value)
    await setShowing(false)
    await setShowing(true) 
  }

  const componentRef = useRef();

  const handleSubmit = async (event) => {

    event.preventDefault();
    event.stopPropagation();

    await db.collection('records')
      .add({
        name: name,
        age: age,
        sex: sex,
        note: note,
        location: location,
        status: status,
        area: area,
        physician: physician,
        lastEdit: lastEdit,
        lastEditTime: dateFormat(new Date(), "dddd, mmmm dS h:MM TT")
      })
      .then(() => {
        setNote('')
        console.log('User added!');
      });
    handleClose();
  };

  const deleteRecord = async (e) => {
    await setDeleteDocId(e.target.value);
    await setDeleteShow(false);
    await setDeleteShow(true);
    // console.log(e.target.value);
    // db.collection('records')
    //   .doc(e.target.value)
    //   .delete()
    //   .then(() => {
    //     console.log('User deleted!');
    //   });
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return history.replace("/");
    setLastEdit(user.email)
  }, [user, loading]);

  useEffect(() => {
    const unSub = db.collection('records').onSnapshot((querySnapshot) => {
      const tempObj = []
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.doc_id = doc.id;
        tempObj.push(data);
      })
      tempObj.sort((a, b) => a.location < b.location ? -1 : 1);
      setRecords(tempObj)
    });
    return () => {
      unSub();
      console.log('unmount')
    }
  }, []);

  const handleEventPositioned = (info) => {
    ReactTooltip.rebuild();
  };

  const Rounds = (props) => {
    if(!props.records) return false;
    return props.records
      .filter(record => record.status === props.status  && record.area === props.area)
      .map(record => 
          <tr key={record.doc_id}  data-tip={record.lastEdit + ' ' + record.lastEditTime}  onMouseOver={handleEventPositioned} >
            <td>
            <span>{record.location}</span></td>
            <td>{record.name}, {record.age}, {record.sex}, {record.physician}</td>
            <td>{ReactHtmlParser(record.note)}</td>
            <td><Button value={record.doc_id} onClick={deleteRecord} size="sm">delete</Button></td>
            <td><Button value={record.doc_id} onClick={editShow} size="sm">edit</Button></td>
          </tr>)
  }
    return (
      <>
      <React.Suspense fallback={<></>}>
        <RecordTheme />
      </React.Suspense>
      <Container> 
      <Row>
      <Col className="roundsActions">
      <Button variant="primary" onClick={handleShow} size="sm">
        Add a patient
      </Button>
      <ReactToPrint
        trigger={() => <Button variant="primary" size="sm">Print List</Button>}
        content={() => {
          console.log(componentRef.current);
          return componentRef.current;
        }}
      />
      </Col>
      </Row>
      <h2>Actives</h2>
      <h4>LICH</h4>
      <Table bordered size="sm" className="roundsList">
      <thead>
      </thead>
      <tbody>
        <Rounds records={records} area="LICH" status="Active" />
        <h4>PBMC</h4>
        <Rounds records={records} area="PBMC" status="Active" />
      </tbody>
      <br />
      <h2>Inactives</h2>
      <h4>LICH</h4>
      <thead> 
      </thead>
      <tbody>
        <Rounds records={records} area="LICH" status="Inactive" />
        <h4>PBMC</h4>
        <Rounds records={records} area="PBMC" status="Inactive" />
      </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add a Patient</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} required />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Age</Form.Label>
                <Form.Control type="text" placeholder="Age" onChange={(e) => setAge(e.target.value)} required />
              </Form.Group>

              <Form.Group as={Col} controlId="sex">
                <Form.Label>Sex</Form.Label>
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check onChange={(e) => setSex(e.target.value)}
                  required
                    inline
                    label="F"
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                    value="F"
                  />
                  <Form.Check onChange={(e) => setSex(e.target.value)}
                  required
                    inline
                    label="M"
                    name="group1"
                    type="radio"
                    id={`inline-radio-2`}
                    value="M"
                  />
                </div>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Room Number</Form.Label>
                <Form.Control type="text" placeholder="Room" onChange={(e) => setLocation(e.target.value)}/>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Attending Physician</Form.Label>
                  <Form.Control type="text" placeholder="Name" onChange={(e) => setPhysician(e.target.value)}/>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="status">
                <Form.Label>Status</Form.Label>
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check required onChange={(e) => setStatus(e.target.value)}
                  required
                    inline
                    label="Active"
                    name="group2"
                    type="radio"
                    id={`inline-radio-1`}
                    value="Active"
                  />
                  <Form.Check onChange={(e) => setStatus(e.target.value)}
                  required
                    inline
                    label="Inactive"
                    name="group2"
                    type="radio"
                    id={`inline-radio-2`}
                    value="Inactive"
                  />
                </div>
                </Form.Group>
              <Form.Group as={Col} controlId="area">
                <Form.Label>Area</Form.Label>
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check required onChange={(e) => setArea(e.target.value)}
                    inline
                    label="LICH"
                    name="group3"
                    type="radio"
                    id={`inline-radio-1`}
                    value="LICH"
                  />
                  <Form.Check onChange={(e) => setArea(e.target.value)}
                    required
                    inline 
                    label="PBMC"
                    name="group3"
                    type="radio"
                    id={`inline-radio-2`}
                    value="PBMC"
                  />
                </div>
              </Form.Group>

            </Row>
                <CKEditor
                    editor={ ClassicEditor }
                    data={note}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setNote(data)
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <ReactTooltip effect="solid" multiline   />
      <EditModal docId={docId} showing={showing} lastEdit={lastEdit} />
          

    <div style={{ display: "none" }}>
      <PrintRounds ref={componentRef} />
    </div>
      </Container>
      <RecordDeleteModal docId={deleteDocId} isShowing={deleteShow} />
      </>
    )
}