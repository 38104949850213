import React, { useEffect, useState, useRef, Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { auth, db, logout } from "./firebase";
import ReactHtmlParser from 'react-html-parser';
import dateFormat from "dateformat";

const PrintRoundsData = () => {
  //Firebase
  const tempObj = [];
  const [actives, setActives] = useState();
  const [inactives, setInactives] = useState();
  const [activesPeconic, setActivesPeconic] = useState();
  const [inactivesPeconic, setinactivesPeconic] = useState();

  const ColoredLine = () => (
    <hr
        style={{
            color: "black",
            borderTop: "1px solid black"
        }}
    />
  );
  useEffect(() => {
    const unSub = db.collection('records').onSnapshot((querySnapshot) => {
      let data;
      tempObj.length = 0;
      querySnapshot.forEach((doc) => {
        data = doc.data();
        data.doc_id = doc.id;
        tempObj.push(data);
      });

      tempObj.sort((a, b) => a.location < b.location ? -1 : 1)
      let actives = tempObj.filter(record => record.status === 'Active' && record.area ==='LICH')
                  .map(record => 
                  		<>
                        <Row><Col>{record.location}, {record.name}, {record.age}, {record.sex}, {record.physician}</Col></Row>
                        <Row><Col>{ReactHtmlParser(record.note)}</Col></Row>
                        <ColoredLine />
                        </>
                        )
      let activesPeconic = tempObj.filter(record => record.status === 'Active' && record.area ==='PBMC')
                  .map(record => 
                  		<>
                        <Row><Col>{record.location}, {record.name}, {record.age}, {record.sex}, {record.physician}</Col></Row>
                        <Row><Col>{ReactHtmlParser(record.note)}</Col></Row>
                        <ColoredLine />
                        </>
                        )            
      let inactives = tempObj.filter(record => record.status === 'Inactive'  && record.area ==='LICH')
                  .map(record => 
                  		<>
                        <Row><Col>{record.location}, {record.name}, {record.age}, {record.sex}, {record.physician}</Col></Row>
                        <Row><Col>{ReactHtmlParser(record.note)}</Col></Row>
                        <ColoredLine />
                        </>
                        )
      let inactivesPb = tempObj.filter(record => record.status === 'Inactive'  && record.area ==='PBMC')
          .map(record => 
          				<>
		                <Row><Col>{record.location}, {record.name}, {record.age}, {record.sex}, {record.physician}</Col></Row>
		                <Row><Col>{ReactHtmlParser(record.note)}</Col></Row>
                    <ColoredLine />
		                </>
		                )

      setActives(actives);
      setInactives(inactives)
      setActivesPeconic(activesPeconic)
      setinactivesPeconic(inactivesPb)
      // RecordsList();
    });
    return () => {
      unSub();
      console.log('unmount')
    }
  }, []);

	return (
		
		<Container fluid="md">
		<h3>Patients List</h3>
		<h4>{dateFormat(new Date(), "dddd, mmmm dS, yyyy")}</h4>
    <h5>Active Patients</h5>
    <h6>LICH</h6>
		<Row>{actives}</Row>
    <h6>PBMC</h6>
		<Row>{activesPeconic}</Row>
    <h6 style={{ fontSize: '12px' }}>Inactive Patients</h6>
    <h6 style={{ fontSize: '10px' }}>LICH</h6>
		<Row style={{ fontSize: '9px' }}>{inactives}</Row>
    <h6 style={{ fontSize: '10px' }}>PBMC</h6>
        <Row style={{ fontSize: '9px' }}>{inactivesPeconic}</Row>
		</Container>
		
	)
}

export default class PrintRounds extends Component {
    render() {
        return <PrintRoundsData />
    }
}