import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import { auth, db, logout } from "./firebase";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Moment from 'react-moment';
import moment from 'moment';

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed
import list from "@fullcalendar/list"; //For List View
import "./Dashboard.css"

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const history = useHistory();
  const [events, setEvents] = useState("");


  const fetchUserName = async () => {
    try {
      const query = await db
        .collection("users")
        .where("uid", "==", user?.uid)
        .get();
      const data = await query.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };
  
  useEffect(() => {
      history.listen((location) => {
          console.log(`You changed the page to: ${location.pathname}`)
      })
  }, [history])

  useEffect(() => {
    if (loading) return;
    if (!user) return history.replace("/");

    fetchUserName();
  }, [user, loading]);

  useEffect(() => {
    // const currentTime = 
    const unSub = db.collection('calendar').onSnapshot((querySnapshot) => {
      const tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push((doc.data()));
      });
      tempArr.map( data => {
        data.className = `${data.className}-dashboard`
      });
      setEvents(tempArr); 
    });
    return () => {
      unSub();
      console.log('unmount')
    }
  }, []);

  // useEffect( async() => {
  //   // const currentTime = 
  //   const unSub = await db.collection('calendar')
  //     .get()
  //     .then(querySnapshot => {
  //       console.log('Total users: ', querySnapshot.size);

  //       querySnapshot.forEach(documentSnapshot => {
  //         // const uh = new moment('2021-10-31')
  //         console.log(moment('2021-10-28').isBetween(documentSnapshot.data().start,documentSnapshot.data().end, undefined, '[]'))
  //         console.log('User ID: ', documentSnapshot.id, documentSnapshot.data().start, documentSnapshot.data().end);
  //       });
  //     });

  //   return () => {
  //     // unSub();
  //     console.log('unmount')
  //   }
  // }, []);

  return (
    <>
    <Container>
    <div className="">
      <div>{value}</div>
      <div className="">
        Logged in as
        <div>{name}</div>
        <div>{user?.email}</div>
        <Button className="" onClick={logout}>
          Logout
        </Button>
      </div>
    </div>
      <FullCalendar
        plugins={[dayGridPlugin, list, interactionPlugin]}
        initialView="listDay"
        events={events}
        eventTextColor="white"
      />
    </Container>
    </>
  );
}

export default Dashboard;
