import Nav from 'react-bootstrap/Nav';
import dateFormat from "dateformat";
import "./AdminMenu.css";

export default function AdminMenu() {
	return (
		<>
    		<Nav className="container-fluid"
			  // activeKey="/records"
			  // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
			>
			  <Nav.Item>
			    <Nav.Link href="/dashboard">Dashboard</Nav.Link>
			  </Nav.Item>
			  <Nav.Item>
			    <Nav.Link href="/records">Rounds</Nav.Link>
			  </Nav.Item>
			  <Nav.Item>
			    <Nav.Link href="/calendar">Calendar</Nav.Link>
			  </Nav.Item>
			  <Nav.Item className="ms-auto">
			  	 <Nav.Link><h5>{dateFormat(new Date(), "dddd, mmmm dS, yyyy")}</h5></Nav.Link>
			  </Nav.Item>
			</Nav>
    	</>
	)
}	