import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { db } from "./firebase";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import dateFormat from "dateformat";

export default function EditModal(props) {
  /*Form Inputs*/
  const [name, setName] = useState(false);
  const [age, setAge] = useState(false);
  const [sex, setSex] = useState(false);
  const [note, setNote] = useState(false);
  const [location, setLocation] = useState(false);
  const [docId, setDocId] = useState(false);
  const [area, setArea] = useState(false);
  const [status, setStatus] = useState(false);
  const [physician, setPhysician] = useState(false);

  /*Form Inputs*/
  const [showing, setShowing] = useState(false);
  const editHandleClose = () => setShowing(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    db.collection('records')
      .doc(props.docId)
      .update({
        name: name,
        sex: sex,
        age: age,
        note: note,
        location: location,
        status: status,
        area: area,
        physician: physician,
        lastEdit: props.lastEdit,
        lastEditTime: dateFormat(new Date(), "dddd, mmmm dS h:MM TT")
      })
      .then(() => {
        console.log('User updated!');
        setShowing(false)
      });
  }

  useEffect(() => {
    console.log('hit')
    if (props.showing) {
      db.collection('records')
      .doc(props.docId)
      .get()
      .then(doc => {
        setName(doc.data().name)
        setSex(doc.data().sex)
        setAge(doc.data().age)
        setLocation(doc.data().location)
        setNote(doc.data().note)
        setStatus(doc.data().status)
        setArea(doc.data().area)
        setPhysician(doc.data().physician)
        setShowing(true)
      })
    }
    // setEditName(Date.now());
  },[props.showing, props.docId])

  return (
    <Modal show={showing} onHide={editHandleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Record</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formEditName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Age</Form.Label>
                <Form.Control type="text" value={age} placeholder="Age" onChange={(e) => setAge(e.target.value)} required />
              </Form.Group>

              <Form.Group as={Col} controlId="sex">
                <Form.Label>Sex</Form.Label>
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check onChange={(e) => setSex(e.target.value)} checked={sex === "F" ?? "checked"}
                  
                    inline
                    label="F"
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                    value="F"
                  />
                  <Form.Check onChange={(e) => setSex(e.target.value)} checked={sex === "M" ?? "checked"}
                  
                    inline
                    label="M"
                    name="group1"
                    type="radio"
                    id={`inline-radio-2`}
                    value="M"
                  />
                </div>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Location</Form.Label>
                <Form.Control type="text" placeholder="Name" value={location} onChange={(e) => setLocation(e.target.value)}/>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Attending Physician</Form.Label>
                  <Form.Control type="text" placeholder="Name" value={physician} onChange={(e) => setPhysician(e.target.value)}/>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="status">
                <Form.Label>Status</Form.Label>
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check onChange={(e) => setStatus(e.target.value)} checked={status === "Active" ?? "checked"}
                  required
                    inline
                    label="Active"
                    name="group2"
                    type="radio"
                    id={`inline-radio-1`}
                    value="Active"
                  />
                  <Form.Check onChange={(e) => setStatus(e.target.value)} checked={status === "Inactive" ?? "checked"}
                  required
                    inline
                    label="Inactive"
                    name="group2"
                    type="radio"
                    id={`inline-radio-2`}
                    value="Inactive"
                  />
                </div>
                </Form.Group>
              <Form.Group as={Col} controlId="area">
                <Form.Label>Area</Form.Label>
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check onChange={(e) => setArea(e.target.value)} checked={area === "LICH" ?? "checked"}
                    required 
                    inline
                    label="LICH"
                    name="group3"
                    type="radio"
                    id={`inline-radio-1`}
                    value="LICH"
                  />
                  <Form.Check onChange={(e) => setArea(e.target.value)} checked={area === "PBMC" ?? "checked"}
                    required
                    inline 
                    label="PBMC"
                    name="group3"
                    type="radio"
                    id={`inline-radio-2`}
                    value="PBMC"
                  />
                </div>
              </Form.Group>

            </Row>
                <CKEditor
                    editor={ ClassicEditor }
                    data={note}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setNote(data)
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
      </Modal.Body>
    </Modal>
  )
}


