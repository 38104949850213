import React, { useEffect, useState, useRef, Component } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import ReactTooltip from 'react-tooltip'
import {isMobile} from 'react-device-detect';
import CalendarDeleteModal from "./CalendarDeleteModal"
import ReactToPrint from 'react-to-print';
import 'bootstrap/dist/css/bootstrap.min.css';
import { auth, db } from "./firebase";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateFormat from "dateformat";

export default function Calendar(props) {
  const inputNoteEl = useRef(null);
  const CalendarTheme = React.lazy(  async() => import('./CalendarTheme'));
  const [events, setEvents] = useState("");
  const calendarRef = useRef();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setErrors('');
    setVacationDoctors([]);
    setOnCallValue(false);
    setHospital([]);
    setStartDate(false);
    setEndDate(false)
  }
  const handleShow = () => setShow(true);
  const refreshPage = ()=>{
    window.location.reload();
  }
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [title, setTitle] = useState(false);
  const [description, setDescription] = useState(false)
  const [deleteShow, setDeleteShow] = useState(false)
  const [docId, setDocId] = useState(false)
  const [test, setTest] = useState(true);
  const [lich, setLich] = useState('');
  const [pbmc, setPbmc] = useState('');
  const [vacationDoctors, setVacationDoctors] = useState([]);
  const [onCallValue, setOnCallValue] = useState(false);
  const [hospital, setHospital] = useState([]);
  const [errors, setErrors] = useState('');
  const componentRef = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if(onCallValue === false && vacationDoctors === false) {
      setErrors('Please select on call or vacation');
      return;
    }
    if (onCallValue) {
      if (hospital.length === 0) {
        setErrors('Please select a hospital');
        return;
      }
      if (onCallValue === false) {
        setErrors('Please select an oncall Dr');
        return;
      }
    }

    if (vacationDoctors) {
      await vacationDoctors.map(data => {
        db.collection('calendar')
          .add({
            allDay: true,
            start: dateFormat(new Date(startDate), "yyyy-mm-dd"),
            startTimestamp: new Date(startDate).getTime(),
            title: data.type + ": " + data.title,
            dr: data.title,
            className: data.title,
            note: inputNoteEl.current.value
          }).then((docRef) => {
            db.collection('calendar')
              .doc(docRef.id)
              .update({
                docId: docRef.id
              })
          })
      })
    }
    if (onCallValue) {
      await db.collection('calendar')
        .add({
          allDay: true,
          start: dateFormat(new Date(startDate), "yyyy-mm-dd"),
          startTimestamp: new Date(startDate).getTime(),
          title:  "OC: " + onCallValue + " " + hospital.sort().join(','),
          dr: onCallValue,
          className: onCallValue,
          note: inputNoteEl.current.value
        }).then((docRef) => {
            db.collection('calendar')
              .doc(docRef.id)
              .update({
                docId: docRef.id
              })
          })
    }

    if (inputNoteEl.current.value) {
      await db.collection('calendar')
        .add({
          allDay: true,
          start: dateFormat(new Date(startDate), "yyyy-mm-dd"),
          startTimestamp: new Date(startDate).getTime(),
          className: "note",
          title: "N: " + inputNoteEl.current.value
        }).then((docRef) => {
            db.collection('calendar')
              .doc(docRef.id)
              .update({
                docId: docRef.id
              })
          })
    }
    handleClose()
  };

  const handleEventPositioned = (info) => {
    if (info.event.classNames[0] === 'off') return false;
    // console.log(info.event.extendedProps.moreprops)
    // info.el.setAttribute("multiline",info.event.extendedProps.moreprops);
    // info.el.setAttribute("data-tip", info.event.extendedProps.docId);
    // ReactTooltip.rebuild();
  };

  const handleEventClick = (info) => {
    if (info.event.classNames[0] === 'off') return false;
    if(isMobile)  return false;
    setDocId(info.event.extendedProps.docId)
    setDeleteShow(false)
    setDeleteShow(true)
    let calendarApi = calendarRef.current.getApi()
    calendarApi.gotoDate('2021-11-01');
    console.log(calendarApi.getDate());
  }

  const handleEventContent = (e) => {
    return (
      <>
        <div>{e.event.title}</div>
      </>
    )
  }

  useEffect(() => {
    const unSub = db.collection('calendar')
    .where('startTimestamp', '>=', new Date().setDate(-30))
    .onSnapshot((querySnapshot) => {
      let tempObj = []
      querySnapshot.forEach((doc) => {
        tempObj.push((doc.data()));
      });
      // console.log(tempObj)
      setEvents(tempObj); 
    });
    return () => {
      unSub();
      console.log('unmount')
    }
  }, []);

  useEffect(() => {
    console.log(hospital.join(','));
  }, [hospital])

  const handleClick = (e) => {
    if (e.target.checked) {
      const doctors = {
        title: e.currentTarget.value, 
        type: e.currentTarget.getAttribute("data-type")
      };
      setVacationDoctors([...vacationDoctors, doctors])
    } else {
      const vacationDoctorsList = vacationDoctors.filter( data => data.title !== e.currentTarget.value )
      setVacationDoctors(vacationDoctorsList)
    }
  }

  const handleOnHospital = (e) => {
    if (e.target.checked) {
      // const hospitals = {
      //   hospital: e.currentTarget.value, 
      //   type: e.currentTarget.getAttribute("data-type")
      // };
      const hospitals = e.currentTarget.value;
      setHospital([...hospital, hospitals])
    } else {
      const hospitalList = hospital.filter( data => data !== e.currentTarget.value )
      setHospital(hospitalList)
    }
  }


  const handleOnCall = (e) => (e.target.checked) ? setOnCallValue(e.target.value) : setOnCallValue(false);
  // const handleOnHospital = (e) => (e.target.checked) ? setHospital(e.target.value) : setHospital(false);

  const handleDateSet = (dateInfo) => {
    console.log(dateInfo.view.getCurrentData().viewApi.currentStart);
    calendarRef.current.getApi().gotoDate(dateInfo.view.getCurrentData().viewApi.currentStart);
  }

  const CalendarPrint = (props) => {
    return (
      <Container className="calendarPrint">
      <Row>
      <Col></Col>
      <Col xs={10}>
      <FullCalendar
            plugins={[ dayGridPlugin, timeGridPlugin ]}
            events={events}
            initialView="dayGridMonth"
            themeSystem='bootstrap'
            ref={calendarRef}
            aspectRatio="1.3"
            headerToolbar = {{
              left: '',
              center: '',
              right: 'title'
            }}
            height="auto"
      />
      </Col>
      <Col></Col>
      </Row>
      </Container>
      )
  }

  class CalendarPrintClass extends Component {
      render() {
          return <CalendarPrint />
      }
  }

	return (
    <>
      <React.Suspense fallback={<></>}>
        <CalendarTheme />
      </React.Suspense>
      <div style={{ overflow: "hidden", height: "0" }}>
        <CalendarPrintClass ref={componentRef} />
      </div>
      
		<Container>
    <Row style={{padding: '0 0 15px'}}>
      <Col className="roundsActions">
      <ReactToPrint
        trigger={() => <Button variant="primary" size="sm">Print Calendar</Button>}
        content={() => {
          // const api = calendarRef.current.getApi();
          // console.log(api.getDate());
          // calendarRef.current.getApi().gotoDate('2021-11-01')
          return componentRef.current;
        }}
      />
      <Button variant="primary" onClick={handleShow} size="sm">
        Add Event
      </Button>
      </Col>
      </Row>
      <Row className="mx-0"></Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors ? <Button variant="warning">{errors}</Button> : ''}
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Start Date</Form.Label>
                <DatePicker required
                  minDate={new Date()} 
                  selected={startDate} 
                  onChange={(date) => setStartDate(date)} 
                  dateFormat="yyyy-MM-dd"
                  />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="doctor">
                <Form.Label>On Call:</Form.Label>
                <div key={`inline-radio`} className="mb-3">
                  {
                    ["AF","BK","RR","EM","IS","KI","EB","JE","CH"].map((data)=>
                      <Form.Check 
                        inline label={data} name="group1" type="checkbox" id={`inline-radio-1`} value={data}
                        checked={data === onCallValue}
                        onChange={handleOnCall} data-type="OC"
                      />
                    )  
                  }
                </div>
              </Form.Group>
                <Form.Group as={Col} controlId="hospital">
                <Form.Label>Hospital:</Form.Label>
                <div key={`hospital`} className="mb-3">
                  {
                    ["LICH", "PBMC", "HAND"].map((data)=>
                      <Form.Check 
                        inline label={data} name="group1" type="checkbox" id={`inline-radio-1`} value={data}
                        // checked={data === hospital}
                        onClick={handleOnHospital} data-type="HO"
                      />
                    )  
                  }
                </div>
              </Form.Group>
            </Row>
            <Row className="mb-3">
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="off">
                <Form.Label>Vacation:</Form.Label>
                <div key={`vacation`} className="mb-3">
                  <Form.Check inline label="AF" name="group1" type="checkbox" id={`vacation-1`} value="AF"
                  onClick={handleClick} data-type="VC"
                  />
                  <Form.Check inline label="BK" name="group1" type="checkbox" id={`vacation-2`} value="BK"
                  onClick={handleClick} data-type="VC"
                  />
                  <Form.Check inline label="RR" name="group1" type="checkbox" id={`vacation-3`} value="RR"
                  onClick={handleClick} data-type="VC"
                  />
                  <Form.Check inline label="EM" name="group1" type="checkbox" id={`vacation-4`} value="EM"
                  onClick={handleClick} data-type="VC"
                  />
                  <Form.Check inline label="IS" name="group1" type="checkbox" id={`vacation-5`} value="IS"
                  onClick={handleClick} data-type="VC"
                  />
                  <Form.Check inline label="KI" name="group1" type="checkbox" id={`vacation-6`} value="KI"
                  onClick={handleClick} data-type="VC"
                  />
                  <Form.Check inline label="EB" name="group1" type="checkbox" id={`vacation-7`} value="EB"
                  onClick={handleClick} data-type="VC"
                  />
                  <Form.Check inline label="CH" name="group1" type="checkbox" id={`vacation-8`} value="CH"
                  onClick={handleClick} data-type="VC"
                  />
                </div>
              </Form.Group>
            </Row>
            <Row className="mb-3">
            <Form.Group as={Col} controlId="note">
                <Form.Label>Note</Form.Label>
                <Form.Control type="text" placeholder="Notes" 
                ref={inputNoteEl}
                />
              </Form.Group>
            </Row>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
			<FullCalendar
		        plugins={[ dayGridPlugin, timeGridPlugin ]}
            events={events}
            eventClick={handleEventClick}
            eventMouseEnter={handleEventPositioned}
            initialView="dayGridMonth"
            headerToolbar = {{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            themeSystem='bootstrap'
            ref={calendarRef}
            eventOrder='title'
            eventOrderStrict='true'
            eventContent={handleEventContent}
            datesSet={handleDateSet}
		    />
        <ReactTooltip effect="solid" multiline   />
        <CalendarDeleteModal docId={docId} showing={deleteShow} title={title} />
		</Container>
    </>
	);
}
