import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import { auth, db, logout } from "./firebase";

export default function CalendarDeleteModal(props) {
	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false)
	}

	const handleDelete = () => {
		console.log(props.docId)
		db.collection('calendar')
	      .doc(props.docId)
	      .delete()
	      .then(() => {
	        console.log('User deleted!');
	        handleClose();
	        // window.location.reload();
	      });
	}

	useEffect(() => {
		console.log(props.showing)
		if (props.showing) {
			setShow(true)
		}
		// setShow(true)
	}, [props.showing])

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>DELETE this record?</Modal.Title>
	        </Modal.Header>
	        <Modal.Body>
	        	<Button onClick={handleDelete}>DELETE</Button>
	        	<Button onClick={handleClose}>CLOSE</Button>
	        </Modal.Body>
		</Modal>
	)
}